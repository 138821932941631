import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { getEnv, getLocalStorageExpire } from 'utils/utils';
import { handleNetworkChange } from 'store/appActions';
import { userLoggedIn, userLoggedOut } from 'store/appThunks';
import { LOGIN_NETWORK_NAME_VERSION } from 'utils/constants';
import { CONFIG_SLICE_NAME } from '../slicesNames';
import defaultConfig from './localConfigurations/defaultConfig';
import configThunks from './configThunks';
import getClientConfig from './localConfigurations/getClientConfig';
import { availableGroupSlugs as selectAvailableGroupSlugs } from './selectNetworks';

const localClientConfig = getClientConfig();
const combinedLocalConfig = { ...defaultConfig, ...localClientConfig };

const loginRegex = new RegExp(...combinedLocalConfig.LOGIN_COOKIE_AUTH_REGEX);
const cookieValue = getLocalStorageExpire(
  combinedLocalConfig.LOGIN_NETWORK_NAME,
  LOGIN_NETWORK_NAME_VERSION
);
export const validLoginCookie = loginRegex.test(cookieValue);

let localStorageNetworkSlug = null;
if (combinedLocalConfig.LOGIN_NETWORK_NAME === 'msftUserId') {
  const memberId = validLoginCookie ? cookieValue : null;
  localStorageNetworkSlug = validLoginCookie ? memberId?.substring(0, 3)?.toLowerCase() : null;
} else {
  localStorageNetworkSlug = validLoginCookie ? cookieValue : null;
}

const initialState = {
  isLoading: false,
  error: null,
  currentNetworkSlug: localStorageNetworkSlug,
  networkGroup: localStorage.getItem('networkGroup'),
  values: {
    ...combinedLocalConfig,
  },
};

const FUSION_DEV_URL = 'https://fusion.dev.emboldhealth.com/api/pg';

// setup local, testing and staging specifics
const env = getEnv();
if (process.env.NODE_ENV === 'development') {
  // determine if we are hitting fusion dev or local fusion
  const apiUrl = localStorage.getItem('devTools:apiUrl') || FUSION_DEV_URL;
  initialState.values.API_URL = apiUrl;
  initialState.values.ENABLE_STYLEGUIDE_ROUTE = true;
  if (apiUrl !== FUSION_DEV_URL) {
    initialState.values.API_TOKEN = `Token ${process.env.REACT_APP_LOCAL_FUSION_SUPERUSER_TOKEN}`;
  }
} else if (env === 'testing') {
  initialState.values.API_URL = FUSION_DEV_URL;
  initialState.values.ENABLE_STYLEGUIDE_ROUTE = true;
} else if (env === 'staging') {
  initialState.values.API_URL = 'https://fusion.staging.emboldhealth.com/api/pg';
  initialState.values.ENABLE_STYLEGUIDE_ROUTE = true;
}

const configSlice = createSlice({
  name: CONFIG_SLICE_NAME,
  initialState,
  reducers: {
    setNetworkSlug(state, action) {
      state.currentNetworkSlug = action.payload;
    },
    overrideValue(state, action) {
      if (process.env.NODE_ENV === 'development') {
        state.values = { ...state.values, ...action.payload };
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(configThunks.fetchClientConfig.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;

        // set additional state from args
        const { network, networkGroup } = action.meta.arg || {};

        if (network) {
          state.currentNetworkSlug = network;
        }

        if (networkGroup) {
          state.networkGroup = networkGroup;
        }
      })
      .addCase(configThunks.fetchClientConfig.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.values = { ...state.values, ...action.payload };

        const availableNetworks = Object.keys(state.values.REGION_SELECTOR_CODES);
        const mpiNetwork = localStorage.getItem('mpiNetwork');

        // if current slug (from previous session) is invalid, clear it
        if (!availableNetworks.includes(state.currentNetworkSlug)) {
          state.currentNetworkSlug = null;
        }

        if (state.values.HAS_PORTAL_INTEGRATION && mpiNetwork) {
          if (availableNetworks.includes(mpiNetwork)) {
            // hide region selector when SSO is used
            state.currentNetworkSlug = mpiNetwork;
            state.values.SHOW_REGION_SELECTOR = false;
          } else {
            // @TODO: what to do if the SSO network from a previous session is invalid?
          }
        }

        // validate that the network group is in the list of available network groups
        if (state.networkGroup) {
          const availableGroupSlugs = selectAvailableGroupSlugs({ [CONFIG_SLICE_NAME]: state });
          if (!availableGroupSlugs.includes(state.networkGroup)) {
            // when the networkGroup is invalid it should be cleared from state and local storage
            Sentry.captureMessage(`Invalid network group: ${state.networkGroup}`, (scope) => {
              scope.setLevel('error');
            });
            state.networkGroup = null;
            localStorage.removeItem('networkGroup');
          }
        }
      })
      .addCase(configThunks.fetchClientConfig.rejected, (state, action) => {
        state.isLoading = false;
        // error message could be passed with rejectWithValue which puts the message in the action.payload
        // OR errors can be thrown which puts them in action.error
        state.error = action.payload || action.error?.message || 'Unknown Error';
      });

    builder.addCase(handleNetworkChange, (state, action) => {
      const networkSlug = action.payload;
      state.currentNetworkSlug = networkSlug;
    });

    builder
      .addCase(userLoggedIn.fulfilled, (state, action) => {
        const { networkSlug } = action.payload;
        state.currentNetworkSlug = networkSlug;
      })
      .addCase(userLoggedOut.fulfilled, (state) => {
        state.currentNetworkSlug = null;
      });
  },
});

export default configSlice;
export const { setNetworkSlug } = configSlice.actions;
