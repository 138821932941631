import React from 'react';
import { renderToString } from 'react-dom/server';

import EmboldLogo from 'assets/embold-icon.png';
import EmboldHeaderLogo from 'assets/embold-logo-full-horizontal.svg';

function CCHSamlLoginForm() {
  return (
    <html lang="en">
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Redirecting...</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato:300" />
        <style>
          {`
            body {
              width: 100vw;
              height: 100vh;
              font-family: 'Lato', sans-serif;
              font-size: 2em;
              background-color: #fafafa;
            }
            main {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
            #header-image {
                width: 300px;
            }
            #loading {
              display: inline-block;
              width: 50px;
              height: 50px;
              animation: spin 1s ease-in-out infinite;
              -webkit-animation: spin 1s ease-in-out infinite;
            }
            #container {
              background-color: #eeeeee;
              padding: 24px 32px;
              border-radius: 16px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            @keyframes spin {
              to {
                -webkit-transform: rotate(360deg);
              }
            }
            @-webkit-keyframes spin {
              to {
                -webkit-transform: rotate(360deg);
              }
            }
            `}
        </style>
      </head>
      <body>
        <header>
          <img id="header-image" src={EmboldHeaderLogo} alt="Embold Health logo" />
        </header>
        <main>
          <section id="container">
            <h1>Redirecting you...</h1>
            <img src={EmboldLogo} alt="Embold Health spinning logo" id="loading" />
            <p>Please do not close this window</p>
          </section>
          <form hidden method="post" name="cch-form">
            <textarea id="samlData" name="SAMLResponse" readOnly />
          </form>
        </main>
      </body>
    </html>
  );
}

export default renderToString(<CCHSamlLoginForm />).replaceAll('&#x27;', "'");
