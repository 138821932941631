import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Button, Typography, Link } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EmboldLoadingSpinner from 'components/EmboldLoadingSpinner';
import CCHSamlLoginForm from 'components/CCH/CCHSamlLoginForm';

export default function CCHCostCostEstimatorButton({ buttonClasses, sendRedirectEvent }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const COST_ESTIMATOR_LINK = useSelector(select.featureFlags.costEstimatorLink);
  const axios = useSelector(select.axios.axiosInstance);

  const redirectToCCH = useCallback(async () => {
    setLoading(true);
    setError(false);
    // we need to open the window in a new tab before the api call to avoid popup blocking
    const formWindow = window.open();
    formWindow.document.write(CCHSamlLoginForm);
    try {
      const response = await axios.get(`/cch-redirect/`);
      const { samlResponse, cchUrl } = response.data;
      if (samlResponse && cchUrl) {
        formWindow.document.getElementById('samlData').value = samlResponse;
        const form = formWindow.document.forms['cch-form'];
        form.action = cchUrl;
        form.submit();
        sendRedirectEvent(cchUrl);
      } else {
        formWindow.location.href = COST_ESTIMATOR_LINK;
        sendRedirectEvent(COST_ESTIMATOR_LINK);
      }
    } catch {
      setError(true);
      formWindow.close();
    }
    setLoading(false);
  }, [COST_ESTIMATOR_LINK, axios, sendRedirectEvent]);

  return (
    <Grid>
      <Button
        onClick={redirectToCCH}
        color="primary"
        variant="contained"
        classes={buttonClasses}
        startIcon={<MonetizationOnIcon />}
        endIcon={loading ? <EmboldLoadingSpinner size="20px" /> : null}
        disabled={loading}
      >
        Cost Estimator Tool
      </Button>

      <Grid aria-live="assertive">
        {error && (
          <Typography color="error">
            We were unable to connect to the cost estimator tool, please try again. If this problem
            persists, please visit our{' '}
            <Link to="/support" component={RouterLink}>
              support page
            </Link>
            .
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

CCHCostCostEstimatorButton.propTypes = {
  buttonClasses: PropTypes.shape({}).isRequired,
  sendRedirectEvent: PropTypes.func.isRequired,
};
