import { resultIsPlaceType, resultIsProviderType } from 'utils/utils';
import { PARAMS, ENDPOINTS } from 'store/fusionServices/fusionConstants';

/**
 * @typedef {Object} responseData
 * @property {number} count The total number of results from a query
 * @property {string|null} next The url of the next page of results
 * @property {string|null} previous The url of the previous page of results
 * @property {object[]} results The array of provider or place objects
 */

/**
 * @typedef {object} singleProviderResponseData
 */

/**
 *
 * @typedef {Object} payloadResponse
 * @property {number} count The total number of results from a query
 * @property {string|null} next The url of the next page of results
 * @property {object} results The object that stores the results indexed by an id
 * @property {string[]} resultIdList The ordered list of id's in the results object
 */

/**
 * Single provider responses that come from the "/providers/{id}" endpoint have a different response shape.
 * This function should alter the response to match the same object shape as a multiple provider response.
 * @param {singleProviderResponseData} responseData
 * @returns {payloadResponse}
 */
export function parseSingleProviderResponseData(responseData) {
  return {
    count: 1,
    filterCounts: {},
    next: null,
    previous: null,
    resultIdList: [responseData.entityId],
    results: {
      [responseData.entityId]: responseData,
    },
  };
}

/**
 * Single provider responses that come from the "/providers/{id}" endpoint have a different response shape.
 * This function should alter the response to match the same object shape as a multiple provider response.
 * @param {singleProviderResponseData} responseData
 * @returns {payloadResponse}
 */
export function parseSinglePlaceResponseData(responseData) {
  return {
    count: 1,
    filterCounts: {},
    next: null,
    previous: null,
    resultIdList: [responseData.id],
    results: {
      [responseData.id]: responseData,
    },
  };
}

/**
 * This function takes the response data from the api formats it to a response object that is used by the Redux reducer.
 * It's primary responsibility is to take the array of results, and store them as an object indexed by id, and an ordered
 * resultsIdList.
 * @param {responseData|singleProviderResponseData} responseData
 * @returns {payloadResponse}
 */
export function parseSearchResponseData(responseData) {
  if (!responseData.results) {
    // if there is no results property but there is an entityId property, this would indicate that this is responseData for a single provider
    if (responseData.entityId) return parseSingleProviderResponseData(responseData);
    // if there is no results property but there is an id property, this would indicate that this is responseData for a single place
    if (responseData.id) return parseSinglePlaceResponseData(responseData);
  }
  // destructure the properties from the response that we wish to utilize
  const { count = 0, filterCounts = {}, next = null, results: resultsArray = [] } = responseData;

  const resultIdList = []; // array to store the ordered list of ID's
  const results = {}; // object to store the results (providers or place objects), indexed by ID

  // iterate over each result in the response and add to the ordered list, and the results object
  for (const result of resultsArray) {
    if (result.id) {
      // must be a place object (provider's do not have an id property), so index by id
      results[result.id] = result;
      resultIdList.push(result.id);
    } else {
      // must be a provider, so index by entityId
      results[result.entityId] = result;
      resultIdList.push(result.entityId);
    }
  }

  const { markdownNotification } = responseData;

  return { count, filterCounts, next, resultIdList, results, markdownNotification };
}

/**
 * Is provided pathname sting the results page pathname
 * @param {string} pathname
 * @returns {bool}
 */
export const isResultsPathname = (pathname) => pathname === '/results';

/** Cigna Cost link */
export const isBehavioralHealthProvider = (provider) => provider?.specialtyId === 15;
export const isHospitalTypeFacility = (facility) => facility?.specialtyId === 302;
export const isBehavioralHealthFacility = (facility) => {
  const subspecialtyIds = facility?.subspecialties?.map(({ subspecialtyId }) => subspecialtyId);
  return subspecialtyIds?.indexOf(456) > -1 || subspecialtyIds?.indexOf(454) > -1;
};

export const getCignaCostLink = (data) => {
  let pathname;
  let nameParam;

  if (!data?.npi) return null;

  if (resultIsProviderType(data)) {
    if (isBehavioralHealthProvider(data)) pathname = 'cbh-providers';
    else pathname = 'doctors';
    nameParam = encodeURIComponent(data.entityName);
  }

  if (resultIsPlaceType(data)) {
    if (isBehavioralHealthFacility(data)) pathname = 'cbh-hospitals';
    else if (isHospitalTypeFacility(data)) pathname = 'hospitals';
    else pathname = 'facilities';
    nameParam = encodeURIComponent(data.name);
  }

  return `https://my.cigna.com/web/secure/consumer/directory/${pathname}/${data.npi}?providerName=${nameParam}`;
};

/**
 * This function take a provider or place searchUrl and changes the endpoint to the corresponding "closest-x-location" endpoint.
 * Then it removes the radius & bounding box params.
 * @param {string} originalUrl
 */
export function getExpandSearchUrl(originalUrl) {
  const urlObj = new URL(originalUrl);
  const params = new URLSearchParams(urlObj.search);

  let path = urlObj.pathname; // should be /api/pg/<result_type>/
  const pathSegments = path.split('/');
  // throw error if a search url with a path param was passed ex: /api/pg/providers/<npi>
  if (pathSegments[4]) throw new Error(`Unexpected number of path segments: ${path}`);

  // replace endpoint
  if (path.includes(ENDPOINTS.PROVIDERS)) {
    path = path.replace(ENDPOINTS.PROVIDERS, ENDPOINTS.CLOSEST_PROVIDERS);
  } else if (path.includes(ENDPOINTS.PLACES)) {
    path = path.replace(ENDPOINTS.PLACES, ENDPOINTS.CLOSEST_PLACES);
  } else {
    throw new Error(`Failed to generate expanded search url for: ${path}`);
  }
  urlObj.pathname = path;

  // remove radius
  if (params.has(PARAMS.RADIUS)) params.delete(PARAMS.RADIUS);

  // remove bounding box
  if (params.has(PARAMS.BOUNDING_BOX)) params.delete(PARAMS.BOUNDING_BOX);

  // add replace original params with new params
  urlObj.search = params.toString();
  return urlObj.toString();
}
