import * as Sentry from '@sentry/react';
import { uniq } from 'lodash';
import { validateBounds, validateLatLong } from 'utils/utils';

export const encodeLocation = (latLng) => {
  if (latLng?.latitude && latLng?.longitude) {
    return `${latLng.latitude},${latLng.longitude}`;
  }
  return undefined;
};

export const decodeLocation = (latLngStr) => {
  if (latLngStr) {
    const [latitudeStr, longitudeStr] = uniq(latLngStr.split(','));
    const latitude = Number(latitudeStr);
    const longitude = Number(longitudeStr);
    if (!validateLatLong(latitude, longitude)) {
      Sentry.captureMessage(
        `Invalid lat/long URL params on profile or results page: ${window.location.href}`
      );
      return null;
    }
    return {
      latitude,
      longitude,
    };
  }
  return null;
};

export const encodeBoundingBox = (bounds) => {
  if (validateBounds(bounds)) {
    return `${bounds.ne.latitude},${bounds.ne.longitude}__${bounds.sw.latitude},${bounds.sw.longitude}`;
  }
  return null;
};

export const decodeBoundingBox = (bounds) => {
  if (bounds) {
    const handleInvalid = () => {
      Sentry.captureMessage(
        `Invalid lat/long URL params on profile or results page: ${window.location.href}`
      );
      return null;
    };

    const [ne, sw] = bounds.split('__');
    if (!ne || !sw) return handleInvalid();

    const [neLat, neLng] = ne.split(',');
    const [swLat, swLng] = sw.split(',');
    if (!validateLatLong(neLat, neLng) || !validateLatLong(swLat, swLng)) return handleInvalid();

    return {
      sw: { longitude: parseFloat(swLng), latitude: parseFloat(swLat) },
      ne: { longitude: parseFloat(neLng), latitude: parseFloat(neLat) },
    };
  }
  return null;
};
