import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { select } from 'store/toolkit';
import { useHistory, withRouter, useParams } from 'react-router-dom';
import { PLACE_TYPE, PROVIDER_TYPE } from 'utils/constants';

import Modal from '../Modal';
import SearchAppendix from './SearchAppendix';

const useStyles = makeStyles(() => ({
  modal: {
    '& [data-reach-dialog-content]': {
      width: '100%',
      maxWidth: 756,
      padding: 0,
      height: '100vh',
    },
    '& .modal-content-wrapper': {
      height: '100%',
    },
  },
}));

const ARIA_ID = 'search-appendix';

function SearchAppendixModal() {
  const classes = useStyles();
  const { resultType } = useParams();
  const isXsDown = useSelector(select.ui.isXsDown);
  const history = useHistory();

  const closeModal = useCallback(() => {
    history.push(`/results${history.location?.state?.background?.search}`);
  }, [history]);

  if ([PROVIDER_TYPE, PLACE_TYPE].indexOf(resultType) === -1) {
    console.error(`Missing or invalid URL path: ${resultType}`);
    const background = history.location?.state?.background;
    if (background) history.push(background);
    else history.push('/');
    return null;
  }

  return (
    <Modal
      handleClose={closeModal}
      open
      ariaId={ARIA_ID}
      hasDescription
      customStyles={classes.modal}
      fullScreen={isXsDown}
    >
      <SearchAppendix resultType={resultType} />
    </Modal>
  );
}

export default withRouter(SearchAppendixModal);
