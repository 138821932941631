import AboutPreferredGroup from 'assets/about-preferred-group.svg';
import AboutExceedsStandards from 'assets/about-exceeds-standards.svg';
import AboutMeetsStandards from 'assets/about-meets-standards.svg';
import AboutNonEvaluated from 'assets/about-non-evaluated.svg';
import AboutBelowStandards from 'assets/about-below-standards.svg';
import EmboldMeasures from 'assets/embold-measures.svg';
import { EMBOLD_MEASURING_WHAT_MATTERS_PDF_URL } from 'utils/constants';

export const ABOUT_SECTION_OPTIONS = {
  PREFERRED_GROUP: 'PREFERRED_GROUP',
  EXCEEDS_STANDARDS: 'EXCEEDS_STANDARDS',
  MEETS_STANDARDS: 'MEETS_STANDARDS',
  NON_EVALUATED: 'NON_EVALUATED',
  BELOW_STANDARDS: 'BELOW_STANDARDS',
  MEASURING_WHAT_MATTERS: 'MEASURING_WHAT_MATTERS',
};

export const SECTION_CONTENT = {
  [ABOUT_SECTION_OPTIONS.PREFERRED_GROUP]: {
    title: 'Preferred Group',
    description:
      'Preferred Groups are practices with the highest percentage of providers in the practice who are rated Exceeds Standards and the lowest percentage of providers who are rated Below Standard. Receiving care from a Preferred Group is easier; even if your provider is not available when you need an appointment, you can see another provider in that group who is likely to also provide the same high quality of patient care.',
    image: AboutPreferredGroup,
  },
  [ABOUT_SECTION_OPTIONS.EXCEEDS_STANDARDS]: {
    title: 'Exceeds Standards',
    description:
      'Based on a clinically validated ranking system, this provider is more likely to provide clinically appropriate care, achieves better outcomes for their patients, and is less likely to perform unnecessary and expensive services.',
    image: AboutExceedsStandards,
  },
  [ABOUT_SECTION_OPTIONS.MEETS_STANDARDS]: {
    title: 'Meets Standards',
    description:
      'Based on a clinically validated ranking system, this provider typically provides clinically appropriate care, achieves average outcomes for their patients, and limits unnecessary and expensive services.',
    image: AboutMeetsStandards,
  },
  [ABOUT_SECTION_OPTIONS.NON_EVALUATED]: {
    title: 'Non-Evaluated',
    description:
      'This provider has not met the minimum data requirements to produce a quality ranking, or their specialty is not currently evaluated for quality. No ranking indicator is displayed for non-evaluated providers.',
    image: AboutNonEvaluated,
  },
  [ABOUT_SECTION_OPTIONS.BELOW_STANDARDS]: {
    title: 'Below Standards',
    description:
      'Based on a clinically validated ranking system, this provider is less likely to provide clinically appropriate care, achieves worse outcomes for their patients, or is more likely to perform unnecessary and expensive services.',
    image: AboutBelowStandards,
  },
  [ABOUT_SECTION_OPTIONS.MEASURING_WHAT_MATTERS]: {
    title: 'Measuring What Matters',
    description:
      'Our Provider Guide solution gives people a fast, easy way to find a doctor the way a doctor would — through thoughtful examination of facts and a focus on patient outcomes. Unlike online reviews or word-of-mouth recommendations, which are based on subjective opinions, Provider Guide is based on science.',
    image: EmboldMeasures,
    link: {
      to: EMBOLD_MEASURING_WHAT_MATTERS_PDF_URL,
      label: 'About our measurements',
      ariaLabel:
        'Learn more about how Embold Health is transforming healthcare by measuring what matters.',
    },
  },
};
