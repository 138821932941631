import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { RefType } from 'propTypes/index';

const useStyles = (bgColor) =>
  makeStyles(() => ({
    root: {
      '& .MuiSelect-outlined': {
        padding: '12px 14px', // This is necessary because the defaultTheme.js is overriding the Mui default padding values for selects
        border: 'none',
      },
      '& .MuiInputBase-input': {
        fontSize: '1rem', // ensures no auto-zoom on iOS safari
      },
    },
    inputLabelBackground: { ...(bgColor ? { background: bgColor } : {}) },
  }));

function TextInput({
  label,
  name,
  children,
  className,
  bgColor,
  InputLabelProps,
  innerRef,
  ...props
}) {
  // useField will look for a 'name' property in props to determine which field in the Formik context to control.
  // It will return an array with a field object and a meta object
  // field contains the name, onChange, onBlur amd value to be passed to our input
  // meta contains the the field error and touched values for determining if there is an error to be shown on the field
  const [field, meta] = useField(name);
  const classes = useStyles(bgColor)();

  return (
    <TextField
      inputRef={innerRef}
      className={`${classes.root} ${className}`}
      label={label}
      variant="outlined"
      // display as error if the field has an error, and has been touched by the user
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
      // spread the formik field props for control
      id={field.name}
      InputLabelProps={{
        classes: { root: classes.inputLabelBackground },
        ...InputLabelProps,
      }}
      {...field}
      // pass any additional input or MUI props needed
      {...props}
    >
      {children}
    </TextField>
  );
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  bgColor: PropTypes.string,
  InputLabelProps: PropTypes.shape({
    classes: PropTypes.shape({ root: PropTypes.string }),
  }),
  innerRef: RefType,
};

TextInput.defaultProps = {
  children: null,
  className: '',
  bgColor: null,
  InputLabelProps: {},
  innerRef: undefined,
};

export default React.forwardRef((props, ref) => <TextInput innerRef={ref} {...props} />);
