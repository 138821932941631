import React, { useMemo } from 'react';
import PropTypes, { SpecialtyDataShape } from 'propTypes/index';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import { getLettersWithSpecialty } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[700],
    padding: `0 ${theme.spacing(0.5)}px`,
    margin: `${theme.spacing(0.5)}px 0`,
    minWidth: 0,
    borderRadius: 0,
    borderBottom: '2px solid transparent',
    flex: 1,
  },
  active: {
    borderColor: theme.palette.secondary.main,
  },
  lineBreak: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export default function LetterAnchorLinks({
  currentLetter,
  specialties,
  scrollToLetter,
  focusOnLetter,
}) {
  const letters = useMemo(() => getLettersWithSpecialty(specialties), [specialties]);
  const classes = useStyles();

  const handleKeyPress = (e, letter) => {
    if (e.shiftKey && e.key === 'Enter') focusOnLetter(letter);
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      {letters.map(({ letter, disabled }, i) => (
        <React.Fragment key={letter}>
          {i === 13 && <div className={classes.lineBreak} />}
          <Button
            variant="text"
            disabled={disabled}
            classes={{ root: clsx(classes.root, { [classes.active]: letter === currentLetter }) }}
            onClick={() => scrollToLetter(letter)}
            onKeyPress={(e) => handleKeyPress(e, letter)}
            aria-current={letter === currentLetter ? 'true' : null}
            aria-label={`Scroll list to specialties starting with ${letter}. ${
              disabled
                ? 'Disabled'
                : `press shift + enter to jump focus to specialties starting with ${letter}`
            }`}
          >
            {letter}
          </Button>
        </React.Fragment>
      ))}
    </Grid>
  );
}
LetterAnchorLinks.propTypes = {
  specialties: PropTypes.arrayOf(SpecialtyDataShape).isRequired,
  scrollToLetter: PropTypes.func.isRequired,
  focusOnLetter: PropTypes.func.isRequired,
  currentLetter: PropTypes.string.isRequired,
};
