import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import PropTypes, { ChildrenType } from 'propTypes/index';

const useStyles = makeStyles((theme) => ({
  p: {
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    fontSize: 16,
  },
}));

function AnchorTag(props) {
  const { href, ...rest } = props;

  const linkProps = useMemo(() => {
    if (href?.startsWith('/')) {
      // internal link
      return {
        component: RouterLink,
        to: href,
      };
    }
    // external link
    return {
      target: '_blank',
      rel: 'noopener noreferrer',
      href,
    };
  }, [href]);

  return <Link {...linkProps} {...rest} />;
}

AnchorTag.propTypes = {
  href: PropTypes.string.isRequired,
};

function Paragraph(props) {
  const classes = useStyles();
  return <Typography classes={{ root: classes.p }} {...props} />;
}

function EmboldMarkdown({ children, markdownProps }) {
  const componentOverrides = markdownProps?.components || {};
  const props = {
    ...markdownProps,
    components: {
      a: AnchorTag,
      p: Paragraph,
      ...componentOverrides,
    },
  };
  return <ReactMarkdown {...props}>{children}</ReactMarkdown>;
}

export default EmboldMarkdown;

EmboldMarkdown.propTypes = {
  children: ChildrenType.isRequired,
  markdownProps: PropTypes.shape({
    components: PropTypes.shape({}),
  }),
};

EmboldMarkdown.defaultProps = {
  markdownProps: {},
};
