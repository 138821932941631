import { DEFAULT_LOCATIONS, SURGERY_PLUS, CHECKMARK } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import lanternHeader from 'assets/lantern_header_logo.png';
import lanternLoginLogo from 'assets/lantern_login_logo.png';

export const apiTokens = {
  dev: 'Token d7b1fd5b743e814c1ced5308af3e9816d81fa43a',
  staging: 'Token 8aabcb9b67e4a4bc9082b9dcc47ca5b762ffd2aa',
  prod: 'Token 3ebb19b307fe12abd5d4b25914782b63729e9ea6',
};

const token = selectToken(apiTokens);

const surgeryPlusConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'surgeryplus',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'surgeryplus',
  EMBOLD_CLIENT: SURGERY_PLUS,
  EMBOLD_CLIENT_SLUG: 'surgeryplus',

  // toggles
  SHOW_COST: false,
  SHOW_TELEHEALTH: false,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  USE_GENDER_ICONS: false,
  SHOW_LAYMANS_INTRO_MODAL: true,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: false,
  LOGIN_COOKIE_AUTH_REGEX: ['surgeryplus', 'i'],

  // other
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Dallas },
  DEFAULT_SEARCH_RADIUS: 50,
  LOGIN_NETWORK_NAME: 'surgeryPlusUserId',

  // content
  DISPLAY_NAME: 'Lantern',
  SCHEDULE_PHONE_NUMBER: '800-555-1234',
  HEADER_LOGO: lanternHeader,
  SUPPORT_LINK: 'https://emboldhealth.com/',
  WELCOME_MESSAGE:
    'Lantern and Embold Health have partnered to bring you Embold‘s Provider Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  FORM_VARIANT: CHECKMARK,
  LOCAL_LOGO_ASSET: lanternLoginLogo,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default surgeryPlusConfig;
