import * as Sentry from '@sentry/react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { apiUrl as selectApiUrl } from 'store/slices/config/selectConfig';
import analyticsSlice from './analyticsSlice';

function getInitialRep() {
  // checkout for ?rep=<repname> so we can filter out traffic
  const params = new URLSearchParams(window.location.search);
  const rep = params.get('rep') || localStorage.getItem('rep') || 'NA';

  // sets localStorage with a string of max length of 30
  localStorage.setItem('rep', rep?.substring(0, 30));

  return rep;
}

function sendEvent(state, evtObj) {
  const url = new URL('/events/', selectApiUrl(state));
  const localToken = localStorage.getItem('integrationToken');
  const integrationToken = localToken || uuidv4();
  if (!localToken) {
    localStorage.setItem('integrationToken', integrationToken);
  }

  url.searchParams.append('rep', getInitialRep());
  url.searchParams.append('timestamp', new Date().getTime());
  url.searchParams.append('integration_token', integrationToken);

  Object.entries(evtObj).forEach(([key, value]) => url.searchParams.append(key, value));

  axios(url.toString())
    .then(() => {})
    .catch((err) => {
      err.sentryMessage = `${err.response?.status || 'No response'} on analytics event: ${
        evtObj?.eventCode
      }`;
      Sentry.captureException(err, (scope) => {
        scope.setFingerprint([
          'get',
          url.pathname,
          err?.response?.status,
          `eventCode:${evtObj?.eventCode || 'none'}`,
        ]);
        scope.setExtra('Event Code', evtObj?.eventCode);
        // include the URL but strip the integration token to avoid content filtering from Sentry
        url.searchParams.set('integration_token', 'REDACTED');
        scope.setExtra('Request URL', url.toString());
        scope.setLevel('warning');
      });
    });
}

const analyticsMiddleware = (store) => (next) => (action) => {
  if (!action.type?.startsWith(analyticsSlice.name)) {
    next(action);
  }

  const state = store.getState();
  const event = analyticsSlice.reducer(state, action);
  if (event) sendEvent(state, event);
};

export default analyticsMiddleware;
