import { DEFAULT_LOCATIONS, DROPDOWN } from 'utils/constants';
import { selectToken, selectMapKey } from 'utils/selectToken';
import emboldLogo from 'assets/embold-icon.png';

const apiTokens = {
  dev: 'Token 56851a33b6945f62efed7bffdca6ca8b1541f308',
  staging: 'Token 7ae1972e6e40c43bdddd5ccc61459eacb39a4642',
  prod: 'Token 86759054b3eca3912ee52276ca5687d167d2099d',
};

const azureMapsKeys = {
  dev: 'KzRiwn3ytsCi9Hp_x5utRumI8eCEnsWPDK9ljSWvJcw',
  staging: 'qHn_TowRlwaSwIVj91ehzljIcE7O_sLFeGvBCVSYa2c',
  prod: '74e5OQx_c0l_8xNlwwKD4BCdl7-6KOkARE42uhzfQAY',
};

const token = selectToken(apiTokens);
const azureMapsKey = selectMapKey(azureMapsKeys);

const defaultConfig = {
  API_TOKEN: token,
  EMBOLD_CLIENT: 'walmart',
  EMBOLD_CLIENT_SLUG: 'wmt',
  HIGH_QUALITY_INTRO_MODAL: false,
  // api & auth
  API_URL: 'https://fusion.prod.emboldhealth.com/api/pg',
  AZURE_MAPS_KEY: azureMapsKey,
  NEIGHBOURS_RADIUS: null,

  // toggles
  CLOSED_ACCESS: false,
  SHOW_COST: false,
  SHOW_TELEHEALTH: true,
  SHOW_WHEELCHAIR_ACCESSIBLE: true,
  SHOW_DISCLAIMER: false,
  SHOW_SCHEDULE_BUTTON: true,
  SHOW_BCBS_LINK: false,
  SHOW_OUTCARE: true,
  SHOW_FILTER_COUNTS: false,
  USE_GENDER_ICONS: true,
  ENABLE_STYLEGUIDE_ROUTE: false,
  ENABLE_PLACE_SEARCH: true,
  MIN_COMPARE_COUNT: 2,
  MAX_COMPARE_COUNT_PROVIDERS: 10, // max amount of providers that can be compared
  MAX_COMPARE_COUNT_PLACES: 3, // max amount of places that can be compared
  PROFILE_COMPARE_THRESHOLD: 3, // shows profile compare verses a table of comparisons in static share page
  SHOW_REGION_SELECTOR: true,
  SHOW_BENEFIT_DIFF: false,
  REGION_SELECTOR_CODES: {},

  // login
  LOGIN_NETWORK_NAME: 'wmtLoggedIn',
  NETWORK_SELECT_API_PATH: 'analytics/network-select/',
  LOGIN_COOKIE_AUTH_REGEX: ['^.*$'], // defaults to any string passing

  // laymans
  SHOW_DR_SCORE: true,
  SHOW_SUBSPECIALTIES: true,
  SHOW_PROVIDER_STRENGTHS: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Dallas },
  HIDE_CLIENT_PROMOTE_HEADER: false,
  HAS_PORTAL_INTEGRATION: false,
  DEFAULT_SEARCH_RADIUS: 25,

  // content
  DISPLAY_NAME: 'Walmart',
  UPDATE_REGION_TEXT: 'In What Region is Your Work Location?',
  HEADER_LOGO: null,
  SCHEDULE_COPY: `Connecting you to customer service to assist with scheduling. Click 'Call Now' to continue.`,
  SUPPORT_LINK: 'https://support.emboldhealth.com',
  FORM_VARIANT: DROPDOWN,
  SHOW_CONTACT_BCBS_ROUTE: false,
  EMBOLD_LOGO_PRESENT: false,
  WELCOME_MESSAGE: `Walmart and Embold Health have partnered to bring you Embold’s Provider Guide, your guide
  to quickly and easily finding the doctors in your network and area that have proven to
  provide the best care. Select your region to find Embold Health’s highest quality
  providers in your area that are also in-network within your plan.`,
  NO_WELCOME_LINK: true,
  LOCAL_LOGO_ASSET: emboldLogo,
};

export default defaultConfig;
