import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import * as Yup from 'yup';

export const TERMS_CHECKBOX = 'terms-checkbox';
export const MONITORING_CHECKBOX = 'monitoring-checkbox';
export const SCENARIO_ID = 'scenario-id';

const basicFields = {
  [TERMS_CHECKBOX]: Yup.bool().oneOf(
    [true],
    'Check the box to agree to the privacy statement and terms of use'
  ),
  [MONITORING_CHECKBOX]: Yup.bool().oneOf([true], 'Check the box to consent to monitoring'),
};
const basicInitialValues = {
  [TERMS_CHECKBOX]: false,
  [MONITORING_CHECKBOX]: false,
};

/**
 * Include scenarioId field in schema and initial values if feature flag is true
 */
const useChatAcknowledgementFormSchema = () => {
  const enableChatScenario = useSelector(select.featureFlags.enableChatScenario);

  return useMemo(() => {
    const schemaFields = enableChatScenario
      ? {
          ...basicFields,
          [SCENARIO_ID]: Yup.number('Select a scenario id').required('Scenario ID is required'),
        }
      : basicFields;

    const initialValues = enableChatScenario
      ? { ...basicInitialValues, [SCENARIO_ID]: '' }
      : basicInitialValues;

    return {
      schema: Yup.object(schemaFields),
      initialValues,
    };
  }, [enableChatScenario]);
};

export default useChatAcknowledgementFormSchema;
