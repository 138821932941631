import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

import EmboldMarkdown from './EmboldMarkdown';

const StyledAlert = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 4,
    borderWidth: 2,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  message: {
    padding: 0,
    textAlign: 'center',
    '& > *': {
      textAlign: 'inherit',
    },
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& li': {
        textAlign: 'left',
      },
    },
    '& h1, h2, h3, h4, h5, h6, ul': {
      margin: 4,
    },
    '& > *:last-child': {
      paddingBottom: 0,
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    '& > *:first-child': {
      fontSize: '2rem',
    },
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1),
    padding: 0,
    gap: theme.spacing(1),
  },
  outlinedInfo: {
    color: theme.palette.primary.main,
  },
  outlinedSuccess: {
    color: theme.palette.primary.main,
  },
  outlinedWarning: {
    color: theme.palette.primary.main,
  },
  outlinedError: {
    color: theme.palette.primary.main,
  },
}))(Alert);

const iconMapping = {
  info: <InfoOutlinedIcon color="inherit" />,
  success: <CheckCircleOutlinedIcon color="inherit" titleAccess="success" />,
  warning: <ErrorIcon color="inherit" titleAccess="warning" />,
  error: <ErrorIcon color="inherit" titleAccess="error" />,
};

export default function MarkdownNotification({ content, ...props }) {
  return (
    <StyledAlert {...props}>
      <EmboldMarkdown>{content}</EmboldMarkdown>
    </StyledAlert>
  );
}

MarkdownNotification.propTypes = {
  // extends https://v4.mui.com/api/alert/#props
  content: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  severity: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  iconMapping: PropTypes.shape({
    info: PropTypes.node,
    success: PropTypes.node,
    warning: PropTypes.node,
    error: PropTypes.node,
  }),
};

MarkdownNotification.defaultProps = {
  variant: 'outlined',
  severity: 'info',
  iconMapping,
};
