import { DEFAULT_LOCATIONS, POLK, CHECKMARK } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import quantumLogo from 'assets/quantum-health-care-finder.svg';

export const apiTokens = {
  dev: 'Token b436afd7ec0e592afc47d5ccd2b2dd9d90bcba50',
  staging: 'Token e85a85fbb2a772a152b055fb83184a44c4594268',
  prod: 'Token f8c8f063bbb77b10da354cd9dc046a13114577d3',
};

const token = selectToken(apiTokens);

const polkConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'polk',

  CLOSED_ACCESS: true,

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'polk',
  EMBOLD_CLIENT: POLK,
  EMBOLD_CLIENT_SLUG: 'polk',
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Lakeland },
  LOGIN_NETWORK_NAME: 'polkUserId',
  LOGIN_COOKIE_AUTH_REGEX: ['polk'],

  // content
  DISPLAY_NAME: 'Polk',
  UPDATE_REGION_TEXT: 'Who am I?',
  HEADER_LOGO: quantumLogo,
  SUPPORT_LINK: 'https://polksupport.emboldhealth.com/portal/',
  FORM_VARIANT: CHECKMARK,
  SCHEDULE_COPY: '',
  SCHEDULE_PHONE_NUMBER: '',
  EMBOLD_LOGO_PRESENT: false,
  WELCOME_MESSAGE: '',
  LOCAL_LOGO_ASSET: quantumLogo,
  NO_WELCOME_LINK: true,
};

export default polkConfig;
