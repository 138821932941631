import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Typography, Container, Grid, Link } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from '@material-ui/icons/AddRounded';

import { select } from 'store/toolkit';
import emboldLogo from 'assets/embold-login-logo.svg';
import { EMBOLD_MEASURING_WHAT_MATTERS_PDF_URL } from 'utils/constants';
import LoginForm from 'components/Forms/Login/LoginForm';
import useUpdateTitle from 'hooks/useUpdateTitle';
import WelcomeMessage from './WelcomeMessage';

const useStyles = makeStyles((theme) => ({
  container: {
    // the grey container that wraps the logos and all content
    backgroundColor: theme.palette.grey[200],
    width: '100%',
    maxWidth: 'none',
    flex: 1, // grow to the whole height of the page
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: '92%',
      flex: 1, // once at SM or above do NOT grow to whole height of page
      padding: theme.spacing(4),
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      borderRadius: 30,
    },
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3),
    '& img': {
      height: 50, // set height, allow width to auto scale to not warp image
      maxWidth: '95%', // will force logos to shrink on the smallest of screens *probably not seen often
      [theme.breakpoints.up('sm')]: { height: 75 },
      [theme.breakpoints.up('md')]: { height: 90 },
      [theme.breakpoints.up('lg')]: { height: 110 },
    },
  },
  innerContainer: {
    // the white container surrounding the text and form
    display: 'flex',
    maxWidth: 1200,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
      borderRadius: 30,
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: { marginTop: theme.spacing(7) },
    [theme.breakpoints.up('lg')]: { marginTop: theme.spacing(10) },
  },
  welcomeContainer: {
    '& h1': {
      fontWeight: 500,
      fontSize: 32,
    },
    [theme.breakpoints.up('md')]: {
      '& h1': { fontSize: 45 },
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    },
    width: '100%',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  formContainer: {
    padding: theme.spacing(5),
    borderRadius: 25,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  addIcon: {
    margin: `0px ${theme.spacing(1)}px`,
  },
  p: {
    paddingBottom: 20,
    textAlign: 'left',
    fontSize: 16,
  },
}));

function WelcomeTitle({ isLoading, ...props }) {
  const text = <Typography {...props} />;

  if (isLoading) return <Skeleton variant="rect">{text}</Skeleton>;
  return text;
}

WelcomeTitle.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default function LoginPage() {
  const classes = useStyles();

  const displayName = useSelector(select.client.displayName);
  const appName = useSelector(select.content.appName);
  const configIsLoading = useSelector(select.config.isLoading);
  const hideWelcomeLink = useSelector(select.featureFlags.hideWelcomeLink);
  const hideWelcomeTitle = useSelector(select.featureFlags.hideWelcomeTitle);
  const showEmboldLogo = useSelector(select.featureFlags.showEmboldLogo);
  const loginImage = useSelector(select.client.loginImage);

  useUpdateTitle('Log In');

  return (
    <Container className={classes.container} id="login-page">
      <Grid container className={classes.imageContainer}>
        <img src={loginImage} alt={`${displayName} ${appName} logo`} />
        {showEmboldLogo && <AddIcon fontSize="large" className={classes.addIcon} />}
        {showEmboldLogo && <img src={emboldLogo} alt="Embold health logo" />}
      </Grid>

      <Grid container item className={classes.innerContainer}>
        {/* Text Content */}
        <Grid item md={6} className={classes.welcomeContainer}>
          {!hideWelcomeTitle && (
            <WelcomeTitle variant="h1" color="primary" isLoading={configIsLoading}>
              Welcome to <br />
              {appName}
            </WelcomeTitle>
          )}
          <WelcomeMessage isLoading={configIsLoading} />

          {!hideWelcomeLink && (
            <Typography className={classes.p}>
              {'Click '}
              <Link
                href={EMBOLD_MEASURING_WHAT_MATTERS_PDF_URL}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Click here to learn more about the Embold Approach"
              >
                here
              </Link>
              {' to find out more about Embold’s method.'}
            </Typography>
          )}
        </Grid>

        <Grid item md={6} className={classes.formContainer}>
          <LoginForm />
        </Grid>
      </Grid>
    </Container>
  );
}
