import { createSelector } from '@reduxjs/toolkit';
import {
  StringParam,
  NumberParam,
  DelimitedArrayParam,
  encodeDelimitedArray,
  decodeDelimitedArray,
} from 'use-query-params';

import filterSlice from 'store/slices/filters/filtersSlice';
import { select } from 'store/toolkit';
import { ARRAY_FILTER_TYPE } from 'store/slices/filters/filterConstants';
import {
  decodeBoundingBox,
  decodeLocation,
  encodeBoundingBox,
  encodeLocation,
} from './queryParamUtils';

const { filters: filtersObject } = filterSlice.getInitialState();

/** Uses an underscore to delimit entries. e.g. ['a', 'b'] => qp=a%20b
 *  --- NOTE --- this is not the suggested way to delimit arrays in the query params as it adds several unnecessary characters.
 * For each array element added to the query string, there it separates them with a %20 (3 chars). A standard DelimitedArray param separates them with a single char _
 * This exists for the sake of supporting legacy links. Any new array params that are added should prefer the use of DelimitedArrayParam
 */
export const UnderscoreArrayParam = {
  encode: (array) => {
    if (!Array.isArray(array)) return undefined;
    if (array.length === 0) return undefined;
    return encodeDelimitedArray(array, '_');
  },
  decode: (arrayStr) => decodeDelimitedArray(arrayStr, '_'),
};

export const BooleanAsStringParam = {
  encode: (bool) => {
    if (bool === true) return 'true';
    return undefined;
  },
  decode: (str) => {
    if (str === 'true') return true;
    return false;
  },
};

// When adding, removing or modifying a Provider Guide query param, use this array.
export const paramBuilder = [
  /*
  {
    key: the key that you want to show in the query string
    selector: the selector function that gets the desired value from ReactReduxContext
    parser: the use-query-params parser function
  },
  */
  {
    key: 'network_slug',
    selector: select.networks.currentSlug,
    parser: StringParam,
  },
  /* ************************************* */
  /* ********** Search Params ************ */
  /* ************************************* */

  {
    key: 'care_category',
    selector: select.results.searchType,
    parser: StringParam,
  },
  {
    key: 'specialtyId',
    selector: select.results.specialtyId,
    parser: NumberParam,
  },
  {
    key: 'subspecialtyId',
    selector: select.results.subspecialtyId,
    parser: NumberParam,
  },
  {
    key: 'serviceId',
    selector: select.results.serviceId,
    parser: NumberParam,
  },
  {
    key: 'serviceType',
    selector: select.results.serviceType,
    parser: {
      encode: (val) => val,
      decode: (val) => {
        if (['provider', 'place'].includes(val)) return val;
        return null;
      },
    },
  },
  {
    key: 'entity_id',
    selector: select.results.entityId,
    parser: StringParam,
  },
  {
    key: 'affiliationName',
    selector: select.search.affiliationName,
    parser: StringParam,
  },
  {
    key: 'affiliationType',
    selector: select.search.affiliationType,
    parser: StringParam,
  },
  {
    key: 'search_input',
    selector: select.results.searchText,
    parser: StringParam,
  },

  /* ************************************* */
  /* ********* Location Params *********** */
  /* ************************************* */
  {
    key: 'city',
    selector: select.results.city,
    parser: StringParam,
  },
  {
    key: 'state',
    selector: select.results.state,
    parser: StringParam,
  },
  {
    key: 'zip',
    selector: select.location.zip,
    parser: StringParam,
  },
  {
    key: 'location_input',
    selector: select.results.locationInput,
    parser: StringParam,
  },
  {
    key: 'radius',
    selector: select.results.searchRadius,
    parser: NumberParam,
  },
  {
    key: 'location',
    selector: select.results.coordinates,
    parser: {
      encode: encodeLocation,
      decode: decodeLocation,
    },
  },
  {
    key: 'bounding_box',
    selector: select.results.boundingBox,
    parser: {
      encode: encodeBoundingBox,
      decode: decodeBoundingBox,
    },
  },

  /* ************************************* */
  /* ********** Result Params ************ */
  /* ************************************* */
  {
    key: 'compareList',
    selector: select.results.compareListIds,
    parser: DelimitedArrayParam,
  },

  /* ************************************* */
  /* ********** Filter Params ************ */
  /* ************************************* */

  // here we are dynamically creating a param object for every filter in the filtersSlice
  ...Object.keys(filtersObject).map((filterKey) => {
    const paramObj = {
      key: filterKey,
      selector: select.results.filterValueByKey(filterKey),
    };

    if (filtersObject[filterKey].type === ARRAY_FILTER_TYPE) {
      paramObj.parser = UnderscoreArrayParam; // array type filters need to be parsed with a UnderscoreArrayParam
    } else {
      paramObj.parser = BooleanAsStringParam; // boolean type filters need to be parsed by the BooleanStringParam
    }

    return paramObj;
  }),

  {
    key: 'ordering',
    selector: select.results.ordering,
    parser: StringParam,
  },
];

/** This is the selector used to gather all of the values for creating the Provider Guide query string */
export const selectUrlDirectSearchParams = createSelector(
  [select.results.url, ...paramBuilder.map((param) => param.selector)],
  (url, ...paramValues) => {
    if (!url) return null; // when no url is present, there has been no searched performed

    const result = {};

    for (let i = 0; i < paramValues.length; i += 1) {
      const paramValue = paramValues[i];
      const paramKey = paramBuilder[i].key;

      if (paramValue !== null) {
        result[paramKey] = paramValue;
      }
    }
    return result;
  }
);

function getQueryParamParser(builder = []) {
  const configObj = {};

  for (const param of builder) {
    configObj[param.key] = param.parser;
  }
  return configObj;
}

export default getQueryParamParser(paramBuilder); // the config object to pass to useQueryParams
