import React, { useCallback } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { select } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { WALMART_NATIONAL } from 'utils/constants';
import CCHCostCostEstimatorButton from './CCHCostEstimatorButton';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(2)}px 0px`,
    width: '100%',
    minWidth: 220,
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export default function CostEstimator() {
  const classes = useStyles();
  const COST_ESTIMATOR_LINK = useSelector(select.featureFlags.costEstimatorLink);
  const client = useSelector(select.client.name);
  const dispatch = useDispatch();

  const sendCostEstimatorRedirectEvent = useCallback(
    (url) => {
      dispatch(_analyticsActions.costEstimatorRedirect(url));
    },
    [dispatch]
  );

  if (!COST_ESTIMATOR_LINK) return null;

  if (client === WALMART_NATIONAL)
    return (
      <CCHCostCostEstimatorButton
        buttonClasses={classes}
        sendRedirectEvent={sendCostEstimatorRedirectEvent}
      />
    );

  return (
    <Button
      href={COST_ESTIMATOR_LINK}
      onClick={() => sendCostEstimatorRedirectEvent(COST_ESTIMATOR_LINK)}
      target="_blank"
      rel="noreferrer"
      color="primary"
      variant="contained"
      classes={classes}
      startIcon={<MonetizationOnIcon />}
    >
      Cost Estimator Tool
    </Button>
  );
}
