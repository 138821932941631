import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, Avatar, Typography } from '@material-ui/core/';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

import { ASSISTANT } from 'store/slices/chat/chatConstants';
import VirtualAssistant from 'icons/VirtualAssistant';
import EmboldIcon from 'assets/embold-icon.png';
import select from 'store/select';

const useStyles = (isAssistantMessage) =>
  makeStyles((theme) => ({
    '@keyframes fadeUpAnimation': {
      from: {
        opacity: 0,
        transform: 'translateY(10px)',
      },
      to: {
        opacity: 1,
      },
    },
    listItem: {
      animationName: '$fadeUpAnimation',
      animationDuration: '.2s',
      animationTimingFunction: 'linear',
      justifyContent: isAssistantMessage ? 'flex-start' : 'flex-end',
      wordWrap: 'break-word',
    },
    message: {
      backgroundColor: isAssistantMessage
        ? theme.palette.common.white
        : theme.palette.secondary.main,
      color: theme.palette.common[isAssistantMessage ? 'black' : 'white'],
      borderRadius: 8,
      padding: 8,
      marginRight: isAssistantMessage ? '1rem' : '0',
      marginLeft: isAssistantMessage ? '0' : '1rem',
      flex: 'inherit',
      '& MuiListItemText-primary': {
        display: 'inline-block',
      },
      maxWidth: '75%',
      whiteSpace: 'pre-wrap',
    },
    avatar: {
      position: 'absolute',
      height: 30,
      width: 30,
      bottom: -4,
      left: -4,
      borderRadius: '100%',
      backgroundColor: theme.palette.primary.main,
      padding: 4,
    },
    emboldAvatar: {
      backgroundColor: theme.palette.common.white,
    },
  }));

function ChatMessage({ message, error }) {
  const { role, content } = message;
  const isAssistantMessage = role === ASSISTANT;
  const classes = useStyles(isAssistantMessage)();
  const isWhitelabeled = useSelector(select.featureFlags.isWhitelabeled);

  const ariaLabel = `${isAssistantMessage ? 'Virtual Assistant' : 'You'} said`;

  const assistantIcon = useMemo(
    () =>
      isWhitelabeled ? (
        <VirtualAssistant
          htmlColor="white"
          titleAccess={ariaLabel}
          classes={{ root: classes.avatar }}
        />
      ) : (
        <Avatar
          alt={ariaLabel}
          src={EmboldIcon}
          classes={{ root: `${classes.avatar} ${classes.emboldAvatar}` }}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isWhitelabeled]
  );

  return (
    <ListItem className={classes.listItem}>
      {role === ASSISTANT ? assistantIcon : <Typography variant="srOnly">{ariaLabel}</Typography>}
      <ListItemText
        className={classes.message}
        primary={<ReactMarkdown components={{ p: 'div', ol: 'dl' }}>{content}</ReactMarkdown>}
        disableTypography
      />
      {(error || message.emergency) && <ErrorIcon color="error" />}
    </ListItem>
  );
}

ChatMessage.propTypes = {
  message: PropTypes.shape({
    role: PropTypes.string,
    content: PropTypes.string,
    emergency: PropTypes.bool,
  }).isRequired,
  error: PropTypes.bool,
};

ChatMessage.defaultProps = {
  error: false,
};

export default React.memo(
  ChatMessage,
  (prev, next) => prev.message.content === next.message.content
);
